/* global G */
/* eslint-disable no-shadow,no-unused-vars */
import { curry, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'
import { back, detail, persistChange, persistCheck, redirect } from 'app/_shared/events'
import action from 'app/_shared/events/action'
import { jump as jumpBack, list as listBreadcrumbs } from 'app/_shared/events/breadcrumbs'
import { addPartToCart } from 'app/_shared/events/cart'
import { getBreadcrumbs, getNode, getNodeType, getTree, setTree } from 'app/_shared/events/file'
import getHierarchy from 'app/_shared/events/file/node/getHierarchy'
import fetchTree from 'app/_shared/events/file/tree/fetchTree'
import remember from 'app/_shared/events/remember'
import mixed, { infiniteMixed } from 'app/_shared/events/search/mixed'
import searchTree from 'app/_shared/events/search/tree'
import isInName from 'app/_shared/events/search/tree/predicates/isInName'
import isInOrderNumber from 'app/_shared/events/search/tree/predicates/isInOrderNumber'
import searchWith from 'app/_shared/events/search/with'
import soon from 'app/_shared/events/soon'
import { setLabel } from 'app/_shared/events/tabs/setCountedLabel'
import { chain, sublist } from 'app/_shared/events/util'
import openElectricDiagram from 'app/equipmentInformation/events/openElectricDiagram'
import redirectToSearchOrDetail from 'app/equipmentInformation/events/redirectToSearchOrDetail'
import persistTab from 'app/_shared/events/tabs/persist'
import getDocumentationTree from 'app/equipmentInformation/events/tree/getDocumentationTree'
import { persistUnsaveChange } from 'app/_shared/events/persist/change'
import isProduct from 'app/equipmentInformation/events/tree/filter/isProduct'
import isDocType from 'app/equipmentInformation/events/tree/filter/isDocType'
import findInitialNode from 'app/equipmentInformation/events/tree/findInitialNode'
import trimAttachmentName from 'app/equipmentInformation/events/tree/trimAttachmentName'
import hasFilter from 'app/equipmentInformation/events/tree/filter/hasFilter'
import { filterTreeAndCleanup } from 'app/_shared/events/file/tree/filterTree'
import getDrilldownTree from 'app/equipmentInformation/events/tree/getDrilldownTree'
import getTreeSteps from 'app/equipmentInformation/events/tree/getTreeSteps'
import getDrilldownNodeLabel from 'app/equipmentInformation/events/tree/getDrilldownNodeLabel'
import setLabels, { setLabelsWith } from 'app/_shared/events/file/tree/setLabels'
import setIcons, { setIconsWith } from 'app/_shared/events/file/tree/setIcons'
import getEquipmentAndRedirect from 'app/equipmentInformation/events/tree/getEquipmentAndRedirect'
import hasCorrectSkipDepth from 'app/_shared/events/file/node/predicates/hasCorrectSkipDepth'
import { getNodeWith } from 'app/_shared/events/file/node/getNode'
import persistChangeAndAction from 'app/_shared/events/combined/persistChangeAndAction'
import listDecisionOptions from 'app/_shared/events/collection/listDecisionOptions'
import persistCheckAndAction from 'app/_shared/events/combined/persistCheckAndAction'
import aggregateDocumentation from 'app/equipmentInformation/events/aggregateDocumentation'
import getFavorite from 'app/_shared/events/favorite/get'
import { openModalWith } from 'app/_shared/events/modal/open'
import { closeModalWith } from 'app/_shared/events/modal/close'
import { submitModalWith } from 'app/_shared/events/modal/submit'
import toggleFavorite, { toggleFavoriteFromAttribute } from 'app/_shared/events/favorite/toggle'
import saveTreeInModule from 'app/_shared/events/file/tree/saveTreeInModule'
import getFromModuleOrFetchTree from 'app/_shared/events/file/tree/getFromModuleOrFetchTree'
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import getAttachmentInfo from 'app/equipmentInformation/events/tree/getAttachmentInfo'

export default {
  onOpen: {
    mixed,
    mixedAndAggregate: curry(async (module, component, event) => {
      const result = await mixed(module, component, event)
      return aggregateDocumentation(module, component, { ...event, detail: { result } })
    }),
    infiniteMixed,
    infiniteMixedAndAggregate: curry(async (module, component, event) => {
      const result = await infiniteMixed(module, component, event)
      return aggregateDocumentation(module, component, { ...event, detail: { result } })
    }),
    infiniteMixedWithFilter: chain(
      searchWith(['filter']),
      infiniteMixed,
    ),
    infiniteMixedWithFilterAndAggregate: chain(
      searchWith(['filter']),
      curry(async (module, component, event) => {
        const result = await infiniteMixed(module, component, event)
        return aggregateDocumentation(module, component, { ...event, detail: { result } })
      }),
    ),
    getTree,
    getTreeSteps,
    getDocumentationTree,
    getDrilldownTree,
    fetchTree,
    getFromModuleOrFetchTree,
    listBreadcrumbs,
    openElectricDiagram,
    pdf: curry((module, component, event) => {
      const model = module[G.MODEL][G.CHILDREN].equipment
      const key = model[G.STATE][G.REF]
      const { version } = model[G.PROPS]
      const { api: { type } } = component[G.PROPS]

      return `/api/v${version}/${type}/${key}/pdf`
    }),
  },
  onTree: {
    setTree,
    substituteIconsAndSetTree: curry((module, component, event) => {
      const treeWithIcons = setIcons(module, component, event)
      const treeWithLabels = setLabelsWith({ key: 'name', source: 'name', target: 'name' }, module, component, treeWithIcons)

      return setTree(module, component, treeWithLabels)
    }),
    substituteAndSetTree: curry((module, component, event) => {
      const treeWithIcons = setIcons(module, component, event)
      const treeWithLabels = setLabels(module, component, treeWithIcons)

      return setTree(module, component, treeWithLabels)
    }),
    substituteDrilldownAndSetTree: curry((module, component, event) => {
      const treeWithIcons = setIconsWith({ target: 'productType' }, module, component, event)
      const treeWithLabels = setLabelsWith({ source: 'productType', target: 'value' }, module, component, treeWithIcons)

      return setTree(module, component, treeWithLabels)
    }),
  },
  onBreadcrumbs: {
    getBreadcrumbs,
  },
  onNode: {
    getNode,
    getNodeOrRedirectToDetail: curry(async (module, component, event) => {
      const node = getNodeWith({ skippable: hasCorrectSkipDepth }, module, component, event)

      if (node?.$children && node.$children[0].type === 'File') {
        await getEquipmentAndRedirect(module, component, { ...event, detail: { node } })
      }

      return node
    }),
  },
  onNodeType: {
    getNodeType,
  },
  onNodeLabel: {
    getDrilldownNodeLabel,
  },
  onNodeHierarchy: {
    getHierarchy,
  },
  onSkip: {
    getEquipmentAndRedirect,
  },
  onAttachment: {
    trimAttachmentName,
  },
  onTreeSearch: {
    searchTree: searchTree([
      isInName,
    ]),
    searchPartInTree: searchTree([
      isInName,
      isInOrderNumber,
    ]),
  },
  onAddPart: {
    addPartToCart,
  },
  onDocumentation: {
    redirectToDocumentationForPart: curry(async (module, component, event) => {
      try {
        const { detail } = event
        const moduleState = module[G.STATE]
        const { route: { documentation: route } } = component[G.CONFIGURATION]
        moduleState[G.ROUTE] = routeComposition(route.module, route.action)
        await redirectSequence(module)({ detail })
      } catch (e) {
        console.error(e)
      }
    }),
  },
  onSearch: {
    persistChangeRememberAndRecreate: curry(async (module, component, event) => {
      // We are using field/search here, not a normal input field. It passes its value as term,
      // but all the other event handlers expect value to be present, so we need to add it here.
      const newEvent = { ...event, detail: { ...event.detail, value: event.detail.term } }

      persistChange(module, component, newEvent)
      await remember(module, component, newEvent)
      await action(module, component, newEvent)

      await module[G.ADAPTER][G.UI].create(module)
    }),
    refreshList: curry(async (module, component, event) => {
      const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
      const { tabs } = find(actionComponent)

      tabs[G.CHILDREN].map(async (tab) => {
        const { searchField, list } = find(tab)
        const term = get(searchField)

        setKey({ ...list[G.STATE][G.META], term }, G.META, list[G.STATE])
        setKey({}, 'reload', list[G.STATE])
      })

      await module[G.ADAPTER][G.UI].update(module)
    }),
  },
  onChange: {
    redirectToSearchOrDetail,
    unsavePersistTreeAndRemember: chain(
      persistUnsaveChange,
      saveTreeInModule,
      remember,
    ),
    persistTabAndRemember: chain(
      persistTab,
      remember,
    ),
    persistChangeAndAction,
    persistCheckAndAction,
    persistChange,
    persistCheck,
    persistChangeRememberAndSynchronize: curry(async (module, component, event) => {
      const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
      const { tabs } = find(actionComponent)

      tabs[G.CHILDREN].map(async (tab) => {
        const { searchField } = find(tab)
        await persistChange(module, searchField, event)
        await remember(module, searchField, event)

        await module[G.ADAPTER][G.UI].update(module)
      })
    }),
  },
  onCart: {
    redirectToCart: curry(async (module, component, event) => {
      try {
        const { detail } = event
        const moduleState = module[G.STATE]
        const { route: { cart: route } } = component[G.CONFIGURATION]
        moduleState[G.ROUTE] = routeComposition(route.module, route.action)
        await redirectSequence(module)({ detail })
      } catch (e) {
        console.error(e)
      }
    }),
  },
  onClick: {
    redirect,
    redirectToSearchOrDetail,
    addPartToCart,
    detail,
    soon,
    jumpBack,
    toggleEquipmentFavorite: toggleFavoriteFromAttribute('equipment'),
    togglePartFavorite: toggleFavoriteFromAttribute('article'),
    feedback: submitModalWith('submitFeedback'),
    favorite: submitModalWith('submitFavorite'),
  },
  onClose: {
    back,
    redirect,
    resetFeedback: closeModalWith('feedbackModal'),
    resetFavorite: closeModalWith('favoriteModal'),
  },
  onFeedbackOpen: {
    showFeedback: openModalWith('feedbackModal'),
  },
  onPage: {
    remember,
  },
  onInitialNode: {
    findInitialNode,
  },
  onFavoriteOpen: {
    getFavorite,
  },
  onFavoriteClick: {
    toggleFavorite,
  },
  onDescriptionOpen: {
    getAttachmentInfo,
  },
  getSelection: {
    listConsentOptions: sublist([1], listDecisionOptions, false),
    listHelpfulOptions: sublist([1, 0], listDecisionOptions, false),
  },
  getLabel: {
    setAllLabel: setLabel([], { ns: 'common', key: 'allResults' }),
    setEquipmentLabel: setLabel([], { ns: 'device', key: 'devices' }),
    setPartsLabel: setLabel([], { ns: 'equipmentInformation', key: 'parts' }),
    setDocumentationLabel: setLabel([], { ns: 'device', key: 'documentation' }),
  },
  filter: {
    isEquipment: curry((module, component, event) => ({
      type: 'equipment',
    })),
    isArticle: curry((module, component, event) => ({
      type: 'article',
    })),
    isDocumentation: curry((module, component, event) => ({
      type: ['documentation', 'docChunk'],
    })),
    isProduct: filterTreeAndCleanup([
      isProduct('equipment'),
    ]),
    isProductAndCleanup: filterTreeAndCleanup([
      isProduct('equipment'),
      hasFilter('equipment', ['energy', 'size']),
    ]),
    isProductAndErrorTreesAndCleanup: filterTreeAndCleanup([
      isProduct('equipment'),
      isDocType('errorTree'),
      hasFilter('equipment', ['energy', 'size']),
    ]),
    isProductAndBulletinAndCleanup: filterTreeAndCleanup([
      isProduct('equipment'),
      isDocType('bulletin'),
      hasFilter('equipment', ['energy', 'size']),
    ]),
    isServiceReferenceAndCleanup: filterTreeAndCleanup([
      isDocType('serviceReference'),
    ]),
  },
}
