/* global G */
/* eslint-disable no-shadow,no-unused-vars */
import { back, detail, persistChange, persistOptions, redirect } from 'app/_shared/events'
import { acl } from 'app/_shared/events/acl'
import action from 'app/_shared/events/action'
import byKey from 'app/_shared/events/action/byKey'
import { jump as jumpBack, list as listBreadcrumbs } from 'app/_shared/events/breadcrumbs'
import addPart from 'app/_shared/events/cart/addPart'
import listCountries from 'app/_shared/events/collection/listCountries'
import listUserCountries from 'app/_shared/events/collection/listUserCountries'
import persistChangeAndAction from 'app/_shared/events/combined/persistChangeAndAction'
import persistOptionsAndAction from 'app/_shared/events/combined/persistOptionsAndAction'
import reset from 'app/_shared/events/model/reset'
import remember from 'app/_shared/events/remember'
import infinite from 'app/_shared/events/search/infinite'
import { infiniteMixed } from 'app/_shared/events/search/mixed'
import searchWith from 'app/_shared/events/search/with'
import soon from 'app/_shared/events/soon'
import { chain } from 'app/_shared/events/util'
import session from 'app/_shared/session'
import previousStep from 'app/cart/events/back'
import changeCartPosition from 'app/cart/events/changeCartPosition'
import detailOrCart from 'app/cart/events/detailOrCart'
import discardCart from 'app/cart/events/discardCart'
import getCartPositions from 'app/cart/events/getCartPositions'
import getUserSalesOrganisations from 'app/cart/events/getUserSalesOrganisations'
import getViewedCartPositions from 'app/cart/events/getViewedCartPositions'
import initTransferAndAction from 'app/cart/events/initTransferAndAction'
import listCartStatus from 'app/cart/events/listCartStatus'
import nextStep from 'app/cart/events/next'
import removeAllPositions from 'app/cart/events/removeAllPositions'
import removePosition from 'app/cart/events/removePosition'
import searchPartAndRecreate from 'app/cart/events/searchPartAndRecreate'
import sendToWebshop from 'app/cart/events/sendToWebshop'
import setCartAsActive from 'app/cart/events/setCartAsActive'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import refresh from 'lib/sequence/model/api/refresh'
import { actionWithHooks } from 'lib/sequence/module/action'
import { curry, getFirstItem } from 'lib/util'

export default {
  acl: {
    acl,
  },
  onOpen: {
    infinite: infinite(null),
    infiniteWithFilterAndSort: curry(async (module, component, event) => {
      const additionalParameters = await searchWith(['filter'], module, component, event)
      const result = await infinite(null, module, component, additionalParameters)

      // Moving active cart element to the top of the list
      result.data.length && result.data.unshift(
        getFirstItem(result.data.splice(
          result.data.findIndex(item => item.value.status === 50), 1,
        )),
      )

      return result
    }),
    infiniteWithFilter: chain(
      searchWith(['filter']),
      infinite(null),
    ),
    infiniteMixedWithFilter: chain(
      searchWith(['filter']),
      infiniteMixed,
    ),
    getCartPositions,
    getViewedCartPositions,
    getUserSalesOrganisations,
    listCountries,
    listCartStatus,
    listBreadcrumbs,
    reset: curry(async (module, component, event) => {
      const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
      const { footer } = sequenceComponentFindProxy(actionComponent)
      const { list } = sequenceComponentFindProxy(footer)

      delete list[G.STATE]?.reload
      delete list[G.STATE]?.[G.META]
      await module[G.ADAPTER][G.UI].update(module)
    }),
    pdf: curry((module, component, event) => {
      const model = module[G.MODEL]
      const { version, api } = model[G.PROPS]
      const key = model[G.STATE][G.REF]

      return `/api/v${version}/${api}/${key}/pdf`
    }),
  },
  onClose: {
    submit: byKey('submit'), // saves the current cart and performs 'back' in after hooks
    back,
    resetAndBack: chain(
      reset(null),
      back,
    ),
  },
  onChange: {
    persistChange,
    persistChangeAndAction,
    persistOptions,
    persistOptionsAndAction,
  },
  onSearch: {
    searchPartAndRecreate,
  },
  onClick: {
    back: previousStep,
    next: nextStep,
    removeAllPositions,
    redirect,
    detail,
    detailOrCart,
    soon,
    addPart: curry(async (module, component, event) => {
      // This will wait until the part is added to the cart and then force a rerender of the
      // parts list in the background, so that the user instantly see's it
      const { list } = sequenceComponentFindProxy(module[G.STATE][G.ACTION][G.COMPONENT])
      const data = await addPart(module, component, event)
      if (data) {
        list[G.STATE].reload = {}
        await actionWithHooks(module[G.STATE][G.ACTION])([])
      }
    }),
    jumpBack,
    resetAndBack: chain(
      reset(null),
      back,
    ),
    goToWebshop: chain(
      reset(null),
      back,
    ),
    sendToWebshop,
    initTransferAndAction,
    checkout: byKey('checkout'),
    discardCartAndRedirect: curry(async (module, component, event) => {
      const deleted = await discardCart(module, component, event)
      deleted
        ? await redirect(module, component, event)
        : await action(module, component, event)
    }),
    setCartActiveAndRedirect: curry(async (module, component, event) => {
      const model = module[G.MODEL]
      const activated = await setCartAsActive(module, component, event)

      // No need to refetch the new cart, we already have it. Just refresh the current model and
      // execute action again
      activated && await refresh(model, activated)

      await redirect(module, component, event)
    }),
  },
  onPage: {
    remember,
  },
  onRemove: {
    removePosition,
  },
  onCommentAdd: {
    changeCartPosition,
  },
  onAmount: {
    changeCartPosition: chain(
      changeCartPosition,
      action,
    ),
  },
  getSelection: {
    listUserCountries,
    getUserSalesOrganisations,
  },
  getStatus: {
    listCartStatus,
  },
  filter: {
    iAmSubmitter: curry((module, component, event) => ({
      submitter: session(module).user.key({ wrap: true })[0],
    })),
    isArticle: curry((module, component, event) => ({
      type: 'article',
    })),
  },
}
