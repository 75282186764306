/* global G */
import routeComposition from 'trait/composition/route'
import sequenceSessionCookieLogout from '@gaia/sequence/app/session/cookie/logout'
import sequenceModuleAdapterSessionStateNavigationReset from '@gaia/sequence/module/adapter/session/navigation'
import sequenceResetAcl from '@gaia/sequence/app/acl/reset'
import seqDisconnectPubSub from 'lib/sequence/app/pubsub/disconnect'

const action = module => () => async (...args) => {
  // disconnect to pubsub channels
  seqDisconnectPubSub(module, null)
  // invalidate cookie
  await sequenceSessionCookieLogout(module)(null)
  // reset nav stack
  sequenceModuleAdapterSessionStateNavigationReset(module, [])
  // reset acl manager
  sequenceResetAcl(module, null)
  // create module.state.route
  const moduleState = module[G.STATE]
  // eslint-disable-next-line no-undefined
  moduleState[G.ROUTE] = routeComposition(undefined, 'index')
  // clearing any errors, to make sure we can go to the route set above
  moduleState[G.ERROR] = false
  // set default context
  module[G.ADAPTER][G.ROUTER].defaultContext()
  // set default language
  await module[G.ADAPTER][G.INTL].defaultLanguage()
  // remove storage's impersonation entry
  module[G.ADAPTER][G.STORAGE].remove('imp')
  // let hook redirect, do its magic
  return args
}

export default action
