/* global G */
import { setKey } from 'lib/util'

/**
 * Stores the route an action is started from inside its module's G.STATE, G.PREV namespace.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 */
const setOrigin = (module) => {
  const previousRoute = module[G.ADAPTER][G.ROUTER].currentRoute()
  const sessionState = module[G.ADAPTER][G.SESSION][G.STATE]
  sessionState[G.ORIGIN] || setKey(previousRoute, G.ORIGIN, sessionState)
}

export default setOrigin
