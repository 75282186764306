/* eslint-disable no-unused-vars */
/* global G */
import { curry, getKey } from 'lib/util'

/**
 * Generates a URL from the given {@code action}, {@code module} and {@code context}.
 *
 * @param {Gaia.AppModule.Spec} module  app module
 * @param {Gaia.Component.Spec} component  action component
 * @param {Gaia.PlatformEvent} event    event
 * @returns {`#/${*}/${*}/${*}`}
 */
export const _url = (module, component, event) => {
  const model = module[G.MODEL]
  const context = getKey(G.CONTEXT, module[G.ADAPTER][G.SESSION][G.STATE])

  const {
    module: targetModule = model[G.CONFIGURATION].module,
    action,
  } = component[G.CONFIGURATION].route

  return `#/${context}/${targetModule}/${action}`
}

/**
 * creates href link from given component
 *
 * @example
 * {
 *  module: 'Card/Form',
 *  view: 'Component/Grid/SectionAsLink',
 *  options: {
 *    md: 12,
 *    xl: 12,
 *    events: {
 *      onOpen: 'linkTo',
 *    },
 *    target: '_blank',
 *  },
 *  route: {
 *    action: 'detail',
 *  },
 *  ...
 *  }
 *
 * @type {Gaia.AppModule.EventHandler}
 * @return {string}
 */
const link = (module, component, event) => {
  const state = component[G.STATE]

  const url = _url(module, component, event)

  const key = state.key
      || event?.key
      || state.value?.[0]?.key
      || state.value?.key

  return key
    ? `${url}/${key}`
    : null
}

export default curry(link)
