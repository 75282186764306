/* global G */
import { asyncPipeSpread, bulk, getFirstItem } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import map from 'lib/sequence/model/api/map'
import { get, set } from 'lib/sequence/component/state/value'
import { hide } from 'lib/sequence/component/state/hidden'
import { displayFavoriteButtonsFromAttribute } from 'app/_shared/action/partial/favorite/displayButtons'
import asObject from 'lib/sequence/component/children/asObject'

/**
 * Retrieves the article from the search and maps it to reflect the actual database structure.
 * Sets G.MODELs (and all sub-models) G.CACHE afterward.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapProperties = module => async (components, ...args) => {
  // model
  const model = module[G.MODEL][G.CHILDREN]?.article || {}

  // component
  const { title, detailsContainer, properties } = components

  const bulkMapModel = bulk(map(model))
  bulkMapModel(title, detailsContainer, properties)

  return [components, ...args]
}

/**
 * Sets the image of the part.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setImage = module => async (components, ...args) => {
  const model = module[G.MODEL][G.CHILDREN]?.article || {}
  const imageCache = model?.[G.CACHE]?.image || {}
  const attachmentId = model?.[G.CACHE]?.value?.attachmentId || null
  const { image } = components

  const partImage = getFirstItem(imageCache)

  if (attachmentId) {
    image[G.PROPS].attachment = {
      key: attachmentId,
      value: partImage.value,
    }
  }

  return [components, ...args]
}

/**
 * Joins the description array into a string.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayDescription = module => async (components, ...args) => {
  const { properties } = components
  const { description } = asObject(properties[G.CHILDREN])

  const descriptionValue = get(description)
  set(description, descriptionValue.join(', '))

  return [components, ...args]
}

/**
 * Hides the product property if it has no value
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayProduct = module => async (components, ...args) => {
  const { properties } = components
  const { product } = asObject(properties[G.CHILDREN])

  !get(product) && hide(product)

  return [components, ...args]
}

/**
 * EquipmentInformation Part Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => () => async (...args) => asyncPipeSpread(
  mapProperties(module),
  setImage(module),
  displayDescription(module),
  displayProduct(module),
  displayFavoriteButtonsFromAttribute('article')(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
