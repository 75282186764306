/* eslint-disable no-unused-vars */
/* global G */
import { curry, deleteKey, setKey } from 'lib/util'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import routeComposition from 'trait/composition/route'

/**
 * Redirects to the current module's state G.PREV route.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 information about the event that triggered this handler
 * @return {Promise<void>}
 */
const toOrigin = async (module, component, event) => {
  try {
    const moduleState = module[G.STATE]
    const sessionState = module[G.ADAPTER][G.SESSION][G.STATE]

    const origin = sessionState[G.ORIGIN] || routeComposition(
      module[G.CONFIGURATION].module,
      module[G.ACTIONS].index._name,
    )

    const key = origin[G.REF]
    setKey(origin, G.ROUTE, moduleState)
    setKey(key, G.REF, module[G.MODEL][G.STATE])
    deleteKey(G.ORIGIN, sessionState)
    await redirectSequence(module)({ key })
  } catch (e) {
    console.error(e)
  }
}

export default curry(toOrigin)
