/* global G, PLATFORM */
import { bulk } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import map from 'lib/sequence/model/api/map'
import getUserRole from 'app/_shared/events/user/role'
import sequenceComponentState from 'lib/sequence/component/state'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { get, set } from 'lib/sequence/component/state/value'
import internalOrganisations from 'model/organisation/collection/internal'

const display = x => (get(x) ? show(x) : hide(x))
const bulkDisplay = bulk(display)

const {
  set: decorate,
  unset: undecorate,
} = sequenceComponentState('decorators')

const {
  set: setRef,
} = sequenceComponentState(G.REF)

/**
 * Returns translated text to be displayed in case the value of a field is not set.
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @returns {Promise<string>}
 */
const notSpecifiedText = async module => await module[G.ADAPTER][G.INTL]._t(
  'label.notSpecified',
  {
    ns: 'common',
    _key: 'label.notSpecified',
    defaultValue: 'Not specified',
  },
)

/**
 * User Action Profile
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @return {function(*): function(...[*]): Promise<*[]>}
 */
const profile = module => component => async (...args) => {
  // model
  const model = module[G.MODEL]
  const { person, team, organisation } = model[G.CHILDREN]
  const { langName } = person[G.CHILDREN]

  // component
  const {
    avatar,
    title,
    role,
    phone,
    officePhone,
    email,
    fax,
    website,
    langName: languageLabel,
    personButton,
    personHeader,
    languageButton,
    teamsSection,
    teamsButton,
    infoList,
    organisationItem,
    accountTypeButton,
  } = sequenceComponentFind(component)

  // Setting avatar
  const personRef = person[G.STATE][G.REF]
  if (get(avatar) !== personRef) {
    set(avatar, personRef)
    setRef(avatar, personRef)
  }

  // Setting user role
  const userRoles = model[G.CACHE]?.value?.roles || []
  const userRole = getUserRole(userRoles, module, component, null)?.value
  userRole && show(role) && set(role, userRole)

  // Mapping
  const bulkMap = bulk(map(model))
  const bulkMapPerson = bulk(map(person))

  bulkMap(organisationItem, teamsButton)
  bulkMapPerson(title, languageButton, infoList)

  // Hiding organisation if it's internal
  const isInternal = internalOrganisations.find(
    x => x.key === organisation[G.STATE][G.REF],
  )
  isInternal && hide(organisationItem)

  // Hiding team section if user has no teams
  !team[G.STATE][G.REF] && hide(teamsSection) // && hide(teamsDivider)

  // Setting language to '-' if user has no language
  langName[G.CACHE]
    ? undecorate(languageLabel)
    : decorate(languageLabel, { error: true })
      && set(languageLabel, await notSpecifiedText(module))

  // Hiding unset fields
  && bulkDisplay(phone, officePhone, email, fax, website)

  if (PLATFORM === 'mobile') {
    hide(personHeader)
    show(personButton)
    bulkMapPerson(personButton)
  }

  // Setting correct label for authentication method
  model[G.CACHE]?.value?.passwordGenerated
    && accountTypeButton[G.CONFIGURATION]?.t?.value?.options?._key
    && (accountTypeButton[G.CONFIGURATION].t.value.options._key = 'label.isPasswordGenerated')

  return args
}

export default profile
