/* eslint-disable no-unused-vars,no-param-reassign */
/* global G, PLATFORM */
import { curry } from 'lib/util'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import routeComposition from 'trait/composition/route'
import session, { settings } from 'app/_shared/session'
import redirectWithUsername from 'app/guest/event/redirectWithUsername'
import { redirect } from 'app/_shared/events'

/**
 * Redirect To Change Password Event Handler
 *
 * Redirects to the component's default route or, if the last validated account has the
 * remoteAuthentication flag and the externalPortalUrl setting is set, redirects to the
 * component's external route.
 *
 * @type {Gaia.AppModule.EventHandler}
 */
const redirectLocalUser = async (module, component, event) => {
  const moduleState = module[G.STATE]
  const { externalRoute } = component[G.CONFIGURATION]
  const user = session(module).user.ref()

  const { remoteAuthentication } = user.value

  if (remoteAuthentication && settings.externalPortalUrl) {
    moduleState[G.ROUTE] = routeComposition(externalRoute.module, externalRoute.action)
    await redirectSequence(module)({})
  } else {
    await redirect(module, component, event)
  }
}

export default curry(redirectLocalUser)
