import ticketTypes from './ticketTypes'
import noteTypes from './noteTypes'
import softwarePrograms from './softwarePrograms'

/**
 * Göttfert's application settings
 */
export default {
  languages: ['en', 'de'],
  suppressPersistence: true,
  suppressProductDevices: true,
  showEquipmentPartsAvailable: true,
  suppressTicketFinishStepForRoles: ['Sales', 'CustomerServiceCooperator'],
  suppressTicketStopDialog: true,
  registerRequestTypes: ['technical_issue', 'other'],
  ticketTypes,
  noteTypes,
  softwarePrograms,
}
