/* global G */
import seqMap from 'lib/sequence/model/api/map'
import { asyncpipe } from 'lib/util'
import seqUpdate from 'lib/sequence/model/api/update'
import seqTransform from 'lib/sequence/model/transformer'
import { updateData } from 'lib/sequence/model/api/submit'

// we are going to respect the new api call structure
//  -> /model/:id/attribute -> user/id/password --> { _rev: '', value: 'attributeValue' }
const submitPassword = async (obj) => {
  const key = obj[G.CHILDREN].change[G.PROPS].api
  const value = obj[G.DATA].change[key]

  const { api, version } = obj[G.PROPS]
  const { _rev, key: _id } = obj[G.CACHE]
  const params = {
    _rev,
    value,
  }
  const url = `/api/v${version}/${api}/${_id}/${key}`
  await obj[G.ADAPTER][G.HTTP].put({ url, params })
  return obj
}

/**
 * User Action Submit
 *
 *
 * @param {Gaia.AppModule.Spec} module
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
const submit = module => () => async (...args) => {
  const model = module[G.MODEL]
  const { person } = model[G.CHILDREN]
  const validationError = model[G.STATE][G.ERROR]
  const moduleState = module[G.STATE]
  moduleState[G.ERROR] = !!validationError

  switch (module[G.STATE][G.ACTION][G.COMPONENT]._name) {
    case module[G.ACTIONS].edit._name:
      !validationError && await asyncpipe(
        seqUpdate,
        seqTransform,
        updateData,
      )(person)
      break
    case module[G.ACTIONS].change._name:
      // we require this mapping, because of requirements decorator.
      (validationError && seqMap(model)(module[G.STATE][G.ACTION][G.COMPONENT]))
      || await submitPassword(model)
      break
    case module[G.ACTIONS].language._name:
      !validationError && await asyncpipe(
        seqUpdate,
        seqTransform,
        updateData,
      )(person)
      // updating application's language
      !validationError && await module[G.ADAPTER][G.INTL].set(person[G.DATA].lang)
      break
    default:
  }

  return args
}

export default submit
