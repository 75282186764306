/* global G */
import ui from 'lib/hook/ui/update'
import validate from 'lib/hook/model/validate'
import confirm from 'lib/hook/router/confirm'
import seqMap from '@gaia/sequence/model/api/map'
import read from 'lib/hook/model/read'
import asObject from 'lib/sequence/component/children/asObject'
import recreate from 'app/_shared/hook/ui/recreate'
import session from 'app/_shared/session'
import provide from 'lib/hook/ui/provide'
import check from 'app/_shared/hook/check'
import back from 'app/_shared/hook/redirect/back'
import clear from 'app/_shared/hook/model/clear'
import { setKey } from 'lib/util'
import togglePhoneValidator from 'app/user/hook/togglePhoneValidator'
import reset from 'app/_shared/hook/component/reset'

const _baseHooks = {
  after: [
    ui,
  ],
}

const _formHooks = {
  before: [
    reset,
  ],
  after: [
    ui,
    confirm,
  ],
}

const _editHooks = {
  before: [
    obj => (...args) => {
      // todo: same as submitAttribute, we should create mapAttribute... :P
      seqMap(
        obj[G.MODEL][G.CHILDREN].person,
      )(
        asObject(obj[G.STATE][G.ACTION][G.COMPONENT][G.CHILDREN]).person,
      )
      return args
    },
  ],
  after: [
    ui,
    confirm,
  ],
}

const _avatarHooks = {
  before: [
    obj => (...args) => {
      // the attachment api expects to use our current model (which is the user), but we need to
      // tell it that we want to manage the person's attachments (avatar)
      const modelState = obj[G.MODEL][G.STATE]
      const { attachment } = asObject(obj[G.STATE][G.ACTION][G.COMPONENT][G.CHILDREN])
      const attachmentState = attachment[G.STATE]
      // eslint-disable-next-line prefer-destructuring
      const personRef = session(obj).person.key()
      attachmentState.value = personRef // upload
      modelState[G.REF] = personRef // listing
      return args
    },
  ],
  after: [
    ui,
    confirm,
  ],
}

const _validateRedirectHooks = {
  before: [
    togglePhoneValidator,
    validate,
  ],
  after: [
    ui,
    check,
    provide,
    clear,
    back,
  ],
}

const _profileHooks = {
  before: [
    obj => (...args) => {
      const userId = session(obj).user.key()
      setKey(userId, G.REF, obj[G.MODEL][G.STATE])
      return args
    },
    // obj => async (...args) => (!obj[G.MODEL][G.CACHE]
    //   ? await recreate(obj)(args)
    //   : args),
    read,
  ],
  after: [
    ui,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    profile: _profileHooks,
    avatar: _avatarHooks,
    edit: _editHooks,
    teams: _baseHooks,
    change: _formHooks,
    language: _editHooks,
    verify: _baseHooks,
    submit: _validateRedirectHooks,
    confirmRemove: _baseHooks,
  },
}

export default hooks
