/**
 * Languages Collection.
 *
 * Contains static list of available ticket's message types, which can be used for different
 * purposes, like filling lists, allowing options selection, etc.
 */
export default [
  { value: 'English', key: 'en' },
  { value: 'German', key: 'de' },
  { value: 'Catalan', key: 'ca' },
]
