/* global G */
import { hide, show } from 'lib/sequence/component/state/hidden'
import getFavorite from 'app/_shared/events/favorite/get'

/**
 * Hides / shows the {@code btnFavorite} and {@code btnNoFavorite} buttons depending
 * on whether the ID of the current model (either {@param attribute} sub model or
 * {@code module[G.MODEL]}) is favored or not.
 *
 * @param {string|null} attribute  sub-model to get payload from
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
const displayFavoriteButtons = attribute => module => async (components, ...args) => {
  const model = module[G.MODEL]
  const payload = attribute
    ? model[G.CHILDREN][attribute][G.STATE][G.REF]
    : model[G.STATE][G.REF]

  const { btnFavorite, btnNoFavorite } = components
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  const isFavorite = getFavorite(module, actionComponent, { detail: { value: payload } })

  isFavorite
    ? hide(btnNoFavorite) && show(btnFavorite)
    : show(btnNoFavorite) && hide(btnFavorite)

  return args
}

/**
 * Wraps {@link displayFavoriteButtons} with the current module's {@code G.MODEL}.
 *
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
export const displayFavoriteButtonsFromModel = displayFavoriteButtons(null)

/**
 * Wraps {@link displayFavoriteButtons} with {@param attribute} as the current sub-model.
 *
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
export const displayFavoriteButtonsFromAttribute = attribute => displayFavoriteButtons(attribute)

export default displayFavoriteButtons(null)
