/* eslint-disable object-curly-newline,no-unused-vars,arrow-body-style,no-param-reassign */
/* global G */
import { asyncPipeSpread, isArr } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import seqModelRead from 'lib/sequence/model/api/read'
import sequenceComponentState from 'lib/sequence/component/state'
import { hide, show } from 'lib/sequence/component/state/hidden'
import session, { settings } from '@app/_shared/session'
import { CONFIRM, SEARCH } from '@app/_shared/events/setStepTab'
import setOrigin from '@app/_shared/component/setOrigin'
import stepper from '@app/_shared/action/stepper'
import organisation from '@app/ticket/action/step/organisation'
import contact from '@app/ticket/action/step/contact'
import type from '@app/ticket/action/step/type'
import device from '@app/ticket/action/step/device'
import party from '@app/ticket/action/step/party'
import issue from '@app/ticket/action/step/issue'
import finish from '@app/ticket/action/step/finish'
import modelReset from '@gaia/sequence/model/api/reset'

const {
  set: setTab,
} = sequenceComponentState('activeTab')

const setSearchTab = step => setTab(step, SEARCH)
const setConfirmTab = step => setTab(step, CONFIRM)

/**
 * Initializes the stepper.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, *, ...[*]): Promise<*[]>}
 */
export const initStepper = module => async (actions, components, ...args) => {
  const { component } = components
  await stepper(module)(component)({ actions, ...args })
  return args
}

/**
 * Hides the finish step and disables the handling of the finish action if the user has any of the
 * roles set in the {@code suppressTicketFinishStepForRoles} setting.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, *, ...[*]): Promise<*[]>}
 */
const toggleFinishStep = module => async (actions, components, ...args) => {
  const { context } = session(module)
  const suppress = settings.suppressTicketFinishStepForRoles
  const { roles } = context()

  const shouldSuppress = suppress && isArr(suppress) && suppress.some(role => roles.includes(role))

  if (shouldSuppress) {
    const { btnContinue, discardBtn, submitBtn } = find(components.issue)
    delete actions.finish
    hide(components.finish)
    hide(btnContinue)
    show(discardBtn)
    show(submitBtn)
  }

  return [actions, components, ...args]
}

/**
 * Gathers the actions for the stepper.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<[object,*,...*[]]>}
 */
const initActions = module => async (components, ...args) => [
  { organisation, contact, type, device, party, issue, finish }, components, ...args,
]

/**
 * Displays the additional party step only if the following conditions are met:
 *  - The ticket has a requesterOrg set
 *  - The ticket has an item set
 *  - The requesterOrg id is different from the one of the item's installedAt
 *
 * Otherwise, the ticket's additionalParty model is cleared and the step hidden.
 *
 * @param module
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
export const displayAdditionalParty = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterOrg, item, additionalParty, additionalPartyData } = model[G.CHILDREN]
  const { installedAt } = item[G.CHILDREN]
  const { party: additionalPartyStep } = components

  requesterOrg[G.CACHE]
  && item[G.CACHE]
  && installedAt[G.CACHE]
  && requesterOrg[G.STATE][G.REF] !== installedAt[G.STATE][G.REF]
    ? show(additionalPartyStep)
    : modelReset(additionalParty)
      && modelReset(additionalPartyData)
      && setSearchTab(additionalPartyStep)
      && hide(additionalPartyStep)

  return [components, ...args]
}

/**
 * Presets the item step if available.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetItem = module => async (components, ...args) => {
  const { item, product, installedAt } = args[0] || {}
  const { component } = components
  if (item) {
    const model = module[G.MODEL]
    // setting models' cache and id
    model[G.CHILDREN].item[G.CACHE] = item[G.CACHE]
    model[G.CHILDREN].item[G.STATE][G.REF] = item[G.STATE][G.REF]
    model[G.CHILDREN].itemData[G.CACHE] = item[G.CACHE]
    model[G.CHILDREN].itemInstalledAt[G.CACHE] = installedAt[G.CACHE]?.[0]
    model[G.CHILDREN].itemInstalledAt[G.STATE][G.REF] = installedAt[G.STATE][G.REF]
    model[G.CHILDREN].product[G.CACHE] = product[G.CACHE]?.[0]
    model[G.CHILDREN].product[G.STATE][G.REF] = product[G.STATE][G.REF]
    // propagating models' cache to their children
    await seqModelRead(model[G.CHILDREN].item)(component)
    await seqModelRead(model[G.CHILDREN].itemData)(component)
    await seqModelRead(model[G.CHILDREN].itemInstalledAt)(component)
    await seqModelRead(model[G.CHILDREN].product)(component)
    // setting step's confirmed tab
    const { device: deviceStep } = components
    setConfirmTab(deviceStep)
  }

  return [components, ...args]
}

/**
 * Presets the contact step if available.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetContact = module => async (components, ...args) => {
  const { contact: requesterContact } = args[0] || {}
  const { component } = components
  if (requesterContact) {
    const model = module[G.MODEL]
    // setting model's cache and id
    model[G.CHILDREN].requesterContact[G.CACHE] = requesterContact[G.CACHE]
    model[G.CHILDREN].requesterContact[G.STATE][G.REF] = requesterContact[G.STATE][G.REF]
    // propagating model's cache to its children
    await seqModelRead(model[G.CHILDREN].requesterContact)(component)
    // setting step's confirmed tab
    const { contact: contactStep } = components
    setConfirmTab(contactStep)
  }

  return [components, ...args]
}

/**
 * Presets the organisation step if available.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetOrganisation = module => async (components, ...args) => {
  const { organisation: requesterOrg } = args[0] || {}
  const { component } = components
  if (requesterOrg) {
    const model = module[G.MODEL]
    // setting model's cache and id
    model[G.CHILDREN].requesterOrg[G.CACHE] = requesterOrg[G.CACHE]
    model[G.CHILDREN].requesterOrg[G.STATE][G.REF] = requesterOrg[G.STATE][G.REF]
    // propagating model's cache to its children
    await seqModelRead(model[G.CHILDREN].requesterOrg)(component)
    // setting step's confirmed tab
    const { organisation: organisationStep } = components
    setConfirmTab(organisationStep)
  }

  return [components, ...args]
}

/**
 * Sets the origin.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(...[*]): Promise<*[]>}
 */
export const initOrigin = module => async (...args) => {
  setOrigin(module)

  return args
}

/**
 * Create action step actions manager.
 *
 * Executes one step action after another.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  initOrigin(module),
  presetOrganisation(module),
  presetContact(module),
  presetItem(module),
  displayAdditionalParty(module),
  initActions(module),
  toggleFinishStep(module),
  initStepper(module),
)(find(component), ...args)
