/**
 * Göttfert's software programs
 */
export default [
  {
    key: 'miCONNECT',
    value: 'miCONNECT',
  },
  {
    key: 'MFRHost',
    value: 'MFRHost',
  },
  {
    key: 'MFRView',
    value: 'MFRView',
  },
  {
    key: 'LabRheo',
    value: 'LabRheo',
  },
  {
    key: 'WinRheo2',
    value: 'WinRheo2',
  },
  {
    key: 'ROSWin',
    value: 'ROSWin',
  },
  {
    key: 'RubberView',
    value: 'RubberView',
  },
  {
    key: 'other',
    value: 'Other',
  },
]
