import { DISABLED, OPTIONAL } from 'model/ticket/collection/note/type'

/**
 * Göttfert's note types
 */
export default [
  // normal types
  {
    key: 'blank',
    value: 'Blank note',
    color: '',
    selectable: true,
    mandatory: false,
    timeTracking: DISABLED,
  },
  {
    key: 'processingStep',
    value: 'Processing Step',
    color: '',
    selectable: true,
    mandatory: false,
    timeTracking: DISABLED,
    startDate: DISABLED,
  },
  {
    key: 'birth',
    value: 'Birth note',
    color: 'primary',
    selectable: false,
    mandatory: false,
    timeTracking: DISABLED,
    startDate: DISABLED,
  },
  {
    key: 'hotline',
    value: 'Hotline',
    color: 'warning',
    mandatory: false,
    timeTracking: OPTIONAL,
    startDate: OPTIONAL,
  },
  // synthetic types
  {
    key: 'timeEntry',
    value: 'Time entry',
    color: '',
    selectable: false,
    mandatory: false,
    timeTracking: OPTIONAL,
    startDate: OPTIONAL,
  },
  // quick action types
  {
    key: 'forward',
    value: 'Forwarded to {team}',
    color: 'primary',
    mandatory: {
      onSuccess: OPTIONAL,
      onFail: DISABLED,
      rules: {
        status: [50],
        assignee: true, // I am assignee
      },
    },
    timeTracking: {
      onSuccess: OPTIONAL,
      onFail: DISABLED,
      rules: {
        status: [50],
        assignee: true, // I am assignee
      },
    },
    startDate: DISABLED,
  },
  {
    key: 'assign',
    value: 'Assigned to {assignee}',
    color: 'primary',
    mandatory: {
      onSuccess: OPTIONAL,
      onFail: DISABLED,
      rules: {
        status: [50],
        assignee: true, // I am assignee
      },
    },
    timeTracking: {
      onSuccess: OPTIONAL,
      onFail: DISABLED,
      rules: {
        status: [50],
        assignee: true, // I am assignee
      },
    },
    startDate: DISABLED,
  },
  {
    key: 'unassign',
    value: 'Unassigned from {assignee}',
    color: 'primary',
    mandatory: {
      onSuccess: OPTIONAL,
      onFail: DISABLED,
      rules: {
        status: [50],
        assignee: true, // I am assignee
      },
    },
    timeTracking: {
      onSuccess: OPTIONAL,
      onFail: DISABLED,
      rules: {
        status: [50],
        assignee: true, // I am assignee
      },
    },
    startDate: DISABLED,
  },
  {
    key: 'postpone',
    value: 'Postponed until {date}',
    color: 'warning',
    mandatory: false,
    timeTracking: OPTIONAL,
    startDate: DISABLED,
  },
  {
    key: 'postpone_customer',
    value: 'Waiting for customer until {date}',
    color: 'warning',
    mandatory: false,
    timeTracking: OPTIONAL,
    startDate: DISABLED,
  },
  {
    key: 'postpone_third_party',
    value: 'Waiting for third party until {date}',
    color: 'warning',
    mandatory: false,
    timeTracking: OPTIONAL,
    startDate: DISABLED,
  },
  {
    key: 'close_resolve',
    value: 'Issue resolved',
    color: 'success',
    mandatory: false,
    timeTracking: OPTIONAL,
    startDate: DISABLED,
  },
  {
    key: 'close_cancel',
    value: 'Issue cancelled',
    color: 'success',
    mandatory: false,
    timeTracking: OPTIONAL,
    startDate: DISABLED,
  },
  {
    key: 'reopen',
    value: 'Issue reopened',
    color: 'primary',
    mandatory: false,
    timeTracking: DISABLED,
    startDate: DISABLED,
  },
  {
    key: 'stop',
    value: 'Stop',
    color: 'disabled',
    mandatory: false,
    timeTracking: OPTIONAL,
    startDate: DISABLED,
  },
]
