/* eslint-disable no-bitwise */
/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import initSyncFactoriesFn from 'lib/factory/sync'
import syncLoadersObject from 'lib/loader'
import contentProviderFn from 'lib/provider/content'
import redirect from '@app/_shared/events/redirect'
import configProviderFn from 'lib/provider/config'
import textField from 'config/web/ui/field/input/2/index.json5'

const MODULE_PATH = 'module/test'
const LABEL = 'field'

/**
 * Maps the {@code module} adapters to be used by {@link initSyncFactoriesFn} as factory providers.
 *
 * @param {Gaia.AppModule.Spec} module  app module
 * @returns {object}                    the factory providers
 */
const factoryProviders = module => ({
  module: {
    ...module[G.ADAPTER],
    [G.UI]: {
      [G.API]: module[G.ADAPTER][G.UI],
    },
    [G.ROUTER]: {
      [G.API]: module[G.ADAPTER][G.ROUTER],
    },
    [G.ACL]: {
      [G.API]: module[G.ADAPTER][G.ACL],
    },
  },
  model: {
    [G.HTTP]: {
      [G.API]: module[G.MODEL][G.ADAPTER][G.HTTP],
    },
    [G.ACL]: {
      [G.API]: module[G.MODEL][G.ADAPTER][G.ACL],
    },
    [G.INTL]: module[G.MODEL][G.ADAPTER][G.INTL],
  },
})

/**
 * Returns a new field configuration with autogenerated key and label.
 *
 * @param {object} item
 * @param {number} index  the field's index
 * @returns {object}      the field's configuration
 */
const fieldItemConfig = (item, index) => {
  // const template = templates[templates.length * Math.random() | 0]
  const template = textField
  const field = { ...template, options: { ...template.options } }
  const key = `${LABEL}${index}`
  field.options.key = key
  field.options.label = key
  return field
}

/**
 * Interceptor function responsible for modifying an action's configuration by setting a
 * {@code count} number of fields' as children of the 'container''s component.
 *
 * @param {Gaia.requireLoaders} configLoader  configuration require loader
 * @param {string} actionName                 the name of the action containing the 'container'
 *                                            component
 * @param {number} count                      the number of fields to insert
 * @returns {function(*=): {options}|*}
 */
const configInterceptor = (configLoader, actionName, count) => (path) => {
  const config = configLoader(path)
  const { options = {} } = config

  options.key === actionName
  && (config.children[5].children = Array.from({ length: count }, fieldItemConfig))

  return config
}

/**
 * Generate event.
 *
 * Fills the 'container' component with the number of fields stated in the 'fields' field.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - event
 * @returns {Promise<void>}
 */
const generate = async (module, component, event) => {
  const processStartTime = performance.now()
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { fields, btnGenerate: button } = asObject(actionComponent[G.CHILDREN])
  const { value } = fields[G.STATE]
  try {
    // parsing fields' value as an int
    const count = parseInt(value, 10)
    console.log(`Generating ${value} fields:`)
    // obtaining the factory object we want use to append the fields' configuration
    const factory = initSyncFactoriesFn(factoryProviders(module))(syncLoadersObject)
    // intercepting the configLoader method, so that we can add the new fields dynamically
    const configLoader = factory.config
    factory.config = configInterceptor(configLoader, actionComponent._name, count)
    // initializing configuration and content providers
    const configProvider = configProviderFn(factory)
    const contentProvider = contentProviderFn(factory)
    // instantiating a new module as the current one, but with the added fields' configuration
    const newModule = contentProvider(configProvider(MODULE_PATH))
    // restoring original configLoader method
    factory.config = configLoader
    console.log(`· Processing time: ${performance.now() - processStartTime}ms`)
    // redirecting to the new module
    const renderStartTime = performance.now()
    await redirect(newModule, button, event)
    console.log(`· Rendering time: ${performance.now() - renderStartTime}ms`)
  } catch (e) {
    console.error(e)
  }
}

export default curry(generate)
