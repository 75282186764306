/* eslint-disable no-unused-vars,no-param-reassign */
/* global G, PLATFORM */
import { curry } from 'lib/util'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import routeComposition from 'trait/composition/route'
import { settings } from 'app/_shared/session'
import redirectWithUsername from 'app/guest/event/redirectWithUsername'

/**
 * Need Help Event Handler
 *
 * Redirects to the component's default route or, if the last validated account has the
 * remoteAuthentication flag and the externalPortalUrl setting is set, redirects to the
 * component's external route.
 *
 * @type {Gaia.AppModule.EventHandler}
 */
const needHelp = async (module, component, event) => {
  const moduleState = module[G.STATE]
  const model = module[G.MODEL]
  const { username } = model[G.DATA]
  const { remoteAuthentication } = model[G.STATE]
  const { externalRoute } = component[G.CONFIGURATION]

  if ((!username || remoteAuthentication) && !!settings.externalPortalUrl) {
    moduleState[G.ROUTE] = routeComposition(externalRoute.module, externalRoute.action)
    await redirectSequence(module)({})
  } else {
    await redirectWithUsername(module, component, event)
  }
}

export default curry(needHelp)
