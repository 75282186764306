/* global G */
import { curry } from 'lib/util'
import { back, persistChange, persistCheck, persistOption, submit } from '@app/_shared/events'
import { list as listBreadcrumbs, jump as jumpBack } from 'app/_shared/events/breadcrumbs'
import persistOptions from '@app/_shared/events/persist/options'
import list, { refList } from '@app/_shared/events/list'
import selectAndAction from '@app/_shared/events/combined/selectAndAction'
import resetAndAction from '@app/_shared/events/combined/resetAndAction'
import search from '@app/_shared/events/search'
import createRedirect from 'app/_shared/events/appbar/createRedirect'
import detail from 'app/_shared/events/action/detail'
import redirect from '@app/_shared/events/redirect'
import link from '@app/_shared/events/link'
import listTicketTypes from 'app/_shared/events/collection/listTicketTypes'
import listInvitationRoles from 'app/_shared/events/collection/listInvitationRoles'
import listTicketStatus from 'app/_shared/events/collection/listTicketStatus'
import listStatusReasons from 'app/_shared/events/collection/listStatusReasons'
import menu from 'app/_shared/events/contextmenu'
import { document, item, reference, user } from 'app/_shared/events/pubsub'
import model from 'app/_shared/events/acl/model'
import component from 'app/_shared/events/acl/component'
import permission from 'app/_shared/events/acl/permission'
import persistStore from 'app/_shared/events/combined/persistStore'
import searchUnsortedAndRecreate from 'app/_shared/events/appbar/searchUnsortedAndRecreate'
import setInviteeRole from 'app/_shared/events/invite/setRole'
import chain from 'app/_shared/events/util/chain'
import searchWith from 'app/_shared/events/search/with'
import setCountedLabel from 'app/_shared/events/tabs/setCountedLabel'
import toRef from 'app/_shared/events/redirect/toRef'
import listInvitationActions from 'app/_shared/events/collection/listInvitationActions'
import listInvitationStatuses from 'app/_shared/events/collection/listInvitationStatuses'
import infinite from 'app/_shared/events/search/infinite'
import session from 'app/_shared/session'
import cell from 'app/_shared/events/acl/cell'
import listOrganisationTypes from 'app/_shared/events/collection/listOrganisationTypes'
import listStatus from 'app/_shared/events/collection/listStatus'
import persistOptionsAndAction from 'app/_shared/events/combined/persistOptionsAndAction'
import persistCheckAndAction from 'app/_shared/events/combined/persistCheckAndAction'
import hasMultipleFilters from 'app/_shared/events/search/query/hasMultipleFilters'
import persistTab from 'app/_shared/events/tabs/persist'
import remember from 'app/_shared/events/remember'
import action from 'app/_shared/events/action'
import searchRequesterTickets from 'app/person/events/searchRequesterTickets'
import redirectWithContact from 'app/person/events/redirectWithContact'
import infinitePersonsWithUsers from 'app/person/events/infinitePersonsWithUsers'
import getInviteStatus from 'app/person/events/getInviteStatus'
import detailWithCount from 'app/person/events/detailWithCount'
import getValidationStatus from 'app/person/events/getValidationStatus'
import redirectToPersonOrUser from 'app/person/events/redirectToPersonOrUser'
// TODO: move to _shared:
import destroyPersonIfNoSubItems from 'app/admin/event/destroyPersonIfNoSubItems'

export default {
  onOpen: {
    listing: list('short'),
    listWithRefs: list(null),
    listVerbose: list('verbose'),
    refListWithCount: refList('defaultWithRequestCount'),
    infinitePersonsWithFilter: chain(
      searchWith(['filter']),
      infinitePersonsWithUsers,
    ),
    infiniteWithFilter: chain(
      searchWith(['filter']),
      infinite(null),
    ),
    infiniteWithCountWithFilter: chain(
      searchWith(['filter']),
      infinite('defaultWithRequestCount'),
    ),
    infiniteVerboseWithFilter: chain(
      searchWith(['filter']),
      infinite('verbose'),
    ),
    search: search(null),
    searchWithFilter: chain(
      searchWith(['filter']),
      search(null),
    ),
    searchVerbose: search('verbose'),
    link,
    toOrganisation: toRef('organisation'),
    reference,
    searchRequesterTickets: searchRequesterTickets(null),
    searchRequesterAllTickets: searchRequesterTickets('verbose'),
    getInviteStatus,
    setTabSearch: searchWith(['filter']),
    getValidationStatus,
    listStatus,
    listBreadcrumbs,
  },
  onPublication: {
    document,
  },
  onClose: {
    redirect,
    back,
  },
  onSearch: {
    searchUnsortedAndRecreate,
  },
  onCreate: {
    createRedirect,
  },
  onClick: {
    redirect,
    redirectToPersonOrUser,
    redirectToRequestWith: redirect,
    redirectToTicketWith: redirectWithContact,
    detail,
    detailWithCount,
    reset: resetAndAction,
    back,
    submit,
    setRoleAndSubmit: chain(
      setInviteeRole(null, 'customer'),
      submit,
    ),
    redirectWithContact,
    destroyIfNoSubItems: destroyPersonIfNoSubItems,
    jumpBack,
  },
  onChange: {
    persistChange,
    persistCheckAndAction,
    persistOption,
    persistOptions,
    persistOptionsAndAction,
    selectAndAction,
    persistStore,
    persistTabAndRemember: chain(
      persistTab,
      remember,
    ),
    persistOptionsRememberAndAction: chain(
      persistOptions,
      remember,
      action,
    ),
    persistCheckRememberAndAction: chain(
      persistCheck,
      remember,
      action,
    ),
  },
  onContextMenu: {
    menu: menu(null),
  },
  onPage: {
    remember,
  },
  getTicketType: {
    listTicketTypes,
  },
  getStatus: {
    listTicketStatus,
    listInvitationStatuses,
  },
  getStatusReason: {
    listStatusReasons,
  },
  getSelection: {
    listInvitationRoles,
    listInvitationActions,
  },
  acl: {
    model,
    component,
    permission,
    acl: component,
    cell,
  },
  list: {
    sub: user,
  },
  item: {
    sub: item,
  },
  getLabel: {
    setReqLabel: setCountedLabel({ ns: 'ticket', key: 'requests' }),
    setCsmLabel: setCountedLabel({ ns: 'ticket', key: 'tickets' }),
    setMaintainedDevicesLabel: setCountedLabel({ ns: 'device', key: 'maintainedDevices' }),
    listOrganisationTypes,
  },
  filter: {
    requesterContact: curry((module, component, event) => ({
      requesterContact: module[G.MODEL][G.STATE][G.REF],
    })),
    isActiveAndMaintainedBy: curry((module, component, event) => ({
      status: { min: 50 },
      maintainedBy: module[G.MODEL][G.STATE][G.REF],
    })),
    notMyselfAndNotInactiveInReq: curry((module, component, event) => {
      const context = module[G.ADAPTER][G.SESSION][G.STATE][G.CONTEXT]
      return { ...context === 'req'
        && { id: `!${session(module).person.key()}`, status: { max: 79 } } }
    }),
    isActive: curry((module, component, event) => ({
      status: { max: 79 },
    })),
  },
  query: {
    personHasStatusAndToBeValidated: hasMultipleFilters('person', ['status', 'toBeValidated']),
  },
}
