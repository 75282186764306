/**
 * Göttfert's ticket types
 */
export default [
  {
    key: 'technical_issue',
    value: 'Technical issue',
    icon: 'on_device_training',
    deviceInput: true,
    deviceIdentify: true,
    softwareInput: true,
  },
  {
    key: 'software_support',
    value: 'Software support',
    icon: 'terminal',
    deviceInput: true,
    deviceIdentify: true,
    softwareInput: true,
  },
  {
    key: 'field_service',
    value: 'Field service',
    icon: 'home_repair_service',
    deviceInput: true,
    deviceIdentify: true,
    fieldServiceInput: true,
  },
  {
    key: 'clarification_spare_parts',
    value: 'Spare part',
    icon: 'settings_suggest',
    deviceInput: true,
    deviceIdentify: true,
  },
]
