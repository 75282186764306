/* global G */
/* eslint-disable no-restricted-globals,no-alert,no-unused-vars */
import { curry } from 'lib/util'
import {
  back,
  bulk,
  edit,
  list,
  persistChange,
  persistCheck,
  persistOption,
  persistOptions,
  redirect,
  submit,
} from '@app/_shared/events'
import { getAttachments, getBreadcrumbs, getNode, getNodeType, getTree, setTree } from 'app/_shared/events/file'
import { jump as jumpBack, list as listBreadcrumbs } from 'app/_shared/events/breadcrumbs'
import search from 'app/_shared/events/search'
import infinite from 'app/_shared/events/search/infinite'
import createRedirect from 'app/_shared/events/appbar/createRedirect'
import searchAndRecreate from 'app/_shared/events/appbar/searchAndRecreate'
import persistNameOption from 'app/_shared/events/persistNameOption'
import detail from 'app/_shared/events/action/detail'
import debounce from 'app/_shared/events/util/debounce'
import chain from 'app/_shared/events/util/chain'
import { confirm, jump, next } from 'app/_shared/events/step'
import persistSerial from 'app/_shared/events/device/persistSerial'
import missingSerial from 'app/_shared/events/device/missingSerial'
import modelSave from 'app/_shared/events/model/save'
import setStepTab, { CONFIRM, LIST, SEARCH } from 'app/_shared/events/setStepTab'
import searchStepAndRecreate from 'app/_shared/events/searchStepAndRecreate'
import persistCheckAndAction from 'app/_shared/events/combined/persistCheckAndAction'
import listCountries from 'app/_shared/events/collection/listCountries'
import listUserCountries from 'app/_shared/events/collection/listUserCountries'
import listTicketTypes from 'app/_shared/events/collection/listTicketTypes'
import listStatus from 'app/_shared/events/collection/listStatus'
import listTicketStatus from 'app/_shared/events/collection/listTicketStatus'
import listStatusReasons from 'app/_shared/events/collection/listStatusReasons'
import listOrganisationTypes from 'app/_shared/events/collection/listOrganisationTypes'
import listSerialFieldOptions from 'app/_shared/events/collection/listSerialFieldOptions'
import link from 'app/_shared/events/link'
import { acl, cell, model } from 'app/_shared/events/acl'
import onKey from 'app/_shared/events/util/onKey'
import menu from 'app/_shared/events/contextmenu'
import { count, item, user } from 'app/_shared/events/pubsub'
import modelReset from 'app/_shared/events/model/reset'
import modelSet from 'app/_shared/events/model/set'
import sublist from 'app/_shared/events/util/sublist'
import session, { settings } from 'app/_shared/session'
import action from 'app/_shared/events/action'
import searchWith from 'app/_shared/events/search/with'
import setCountedLabel, { setRefLabel } from 'app/_shared/events/tabs/setCountedLabel'
import searchUnsortedAndRecreate from 'app/_shared/events/appbar/searchUnsortedAndRecreate'
import modelStateSave from 'app/_shared/events/model/stateSave'
import persistStore from 'app/_shared/events/combined/persistStore'
import persistOptionsAndAction from 'app/_shared/events/combined/persistOptionsAndAction'
import hasMultipleFilters from 'app/_shared/events/search/query/hasMultipleFilters'
import searchTree from 'app/_shared/events/search/tree'
import isInName from 'app/_shared/events/search/tree/predicates/isInName'
import remember from 'app/_shared/events/remember'
import persistTab from 'app/_shared/events/tabs/persist'
import searchMaintainedDevice from 'app/serviceItem/event/searchMaintainedDevice'
import destroyIfNoSubItems from 'app/serviceItem/event/destroyIfNoSubItems'
import getMaintainers from 'app/serviceItem/event/getMaintainers'
import searchProduct from 'app/serviceItem/event/searchProduct'
import searchDevice from 'app/serviceItem/event/searchDevice'
import setProduct from 'app/serviceItem/event/setProduct'
import redirectWithItem from 'app/serviceItem/event/redirectWithItem'
// TODO: move to _shared:
import getValidationStatus from 'app/person/events/getValidationStatus'
import updateUnreadMessagesCount from 'app/ticket/event/updateUnreadMessagesCount'
import getMergedTree from 'app/_shared/events/file/tree/getMergedTree'

export default {
  acl: {
    acl,
    cell,
    model,
  },
  onOpen: {
    listing: list('short'),
    listWithRefs: list(null),
    listVerbose: list('verbose'),
    search: search(null),
    searchVerbose: search('verbose'),
    searchWithFilter: chain(
      searchWith(['filter']),
      search(null),
    ),
    listCountries,
    link,
    infinite: infinite(null),
    infiniteVerboseWithFilter: chain(
      searchWith(['filter']),
      infinite('verbose'),
    ),
    infiniteWithRequestCountAndFilter: chain(
      searchWith(['filter']),
      infinite('defaultWithRequestCount'),
    ),
    infiniteWithTicketCount: infinite('defaultWithTicketCount'),
    infiniteMaintainedWithRequestCount: curry(async (module, component, event) => {
      const additionalProperties = await searchWith(['filter'], module, component, event)
      return infinite('defaultWithRequestCount', module, component, additionalProperties)
    }),
    getTree,
    getMergedTree,
    getBreadcrumbs,
    setTabSearch: searchWith(['filter']),
    getMaintainers,
    getValidationStatus,
    listStatus,
    listBreadcrumbs,
  },
  onTree: {
    setTree,
  },
  onTreeSearch: {
    searchTree: searchTree([
      isInName,
    ]),
  },
  onNode: {
    getNode,
  },
  onNodeType: {
    getNodeType,
  },
  onAttachment: {
    getAttachments,
  },
  onBreadcrumbs: {
    getBreadcrumbs,
  },
  onSearch: {
    searchUnsortedAndRecreate,
    searchAndRecreate,
    searchStepAndRecreate,
  },
  onCreate: {
    createRedirect,
  },
  onClose: {
    redirect,
    back,
  },
  onClick: {
    edit,
    redirect,
    submit,
    bulk,
    detail,
    back,
    jump,
    jumpBack,
    next,
    confirmStep: chain(
      confirm,
      next,
    ),
    missingSerial,
    searchProduct,
    searchMaintainedDevice,
    setProduct,
    setLocation: chain(
      modelSet('installedAt'),
      modelStateSave('installedAt'),
      setStepTab(CONFIRM),
    ),
    unsetItem: chain(
      modelReset(),
      setStepTab(SEARCH),
    ),
    unsetLocation: chain(
      modelReset('installedAt'),
      setStepTab(SEARCH),
    ),
    saveLocation: modelSave('installedAt'),
    redirectToTicketWith: redirectWithItem,
    redirectToRequestWith: redirectWithItem,
    toList: setStepTab(LIST),
    toConfirm: setStepTab(CONFIRM),
    toSearch: setStepTab(SEARCH),
    /**
     * Fired whenever the user clicks on the button "I still want to register the device".
     *
     * If the user has the suppressProductDevices setting, redirects them to the device confirmation
     * tab, otherwise it redirects them to the product selection tab.
     *
     * @type {Gaia.AppModule.EventHandler}
     */
    registerDevice: curry((module, component, event) => {
      const tab = settings.suppressProductDevices ? CONFIRM : LIST
      setStepTab(tab)(module, component, event)
    }),
    destroyIfNoSubItems,
  },
  onChange: {
    persistNameOption,
    persistChange,
    persistOptions,
    persistOptionsAndAction,
    persistOption,
    persistStore,
    persistCheckAndAction,
    persistCountry: persistOptions,
    persistSerial,
    searchDevice: debounce(chain(
      persistChange,
      searchDevice,
    ), 500),
    persistTabAndRemember: chain(
      persistTab,
      remember,
    ),
    persistOptionsRememberAndAction: chain(
      persistOptions,
      remember,
      action,
    ),
    persistCheckRememberAndAction: chain(
      persistCheck,
      remember,
      action,
    ),
  },
  onBlur: {
    searchSerial: searchDevice,
  },
  onKeyPress: {
    searchProduct: onKey('Enter', searchProduct),
    searchSerial: onKey('Enter', searchDevice),
  },
  list: {
    sub: user,
  },
  item: {
    sub: item,
  },
  cell: {
    sub: updateUnreadMessagesCount,
  },
  count: {
    sub: count,
  },
  onContextMenu: {
    menu: menu(null),
  },
  onPage: {
    remember,
  },
  onLinkChange: {
    setLink: link,
  },
  getOrganisationType: {
    listOrganisationTypes,
  },
  getTicketType: {
    listTicketTypes,
  },
  getStatus: {
    listTicketStatus,
    listStatus,
  },
  getStatusReason: {
    listStatusReasons,
  },
  getSelection: {
    listOrganisationTypes: sublist(
      ['customer', 'independent_contractor', 'internal', 'partner'],
      listOrganisationTypes,
    ),
    listUserCountries,
  },
  getLabel: {
    setReqLabel: setCountedLabel({ ns: 'ticket', key: 'requests' }),
    setCsmLabel: setCountedLabel({ ns: 'ticket', key: 'tickets' }),
    setMaintainedByLabel: setRefLabel('maintainedBy', { ns: 'device', key: 'maintainedBy' }),
    listOrganisationTypes,
  },
  getFieldOptions: {
    listSerialFieldOptions,
  },
  filter: {
    serviceItem: curry((module, component, event) => ({
      item: module[G.MODEL][G.STATE][G.REF],
    })),
    availableAndMaintainedByMe: curry((module, component, event) => ({
      status: { min: 1, max: 79 },
      maintainedBy: session(module).person.key({ wrap: true })[0],
    })),
    isNotInactive: curry((module, component, event) => ({
      status: { max: 79 },
    })),
    isActiveAndNotMyself: curry((module, component, event) => ({
      status: 50,
      id: `!${module[G.MODEL][G.STATE][G.REF]}`,
    })),
  },
  query: {
    serviceitemHasStatusAndToBeValidated: hasMultipleFilters('serviceitem', ['status', 'toBeValidated']),
  },
}
