/* eslint-disable no-unused-vars */
/* global G */
import { asyncPipeSpread, bulk, getFirstItem, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import map from 'lib/sequence/model/api/map'
import { get, set } from 'lib/sequence/component/state/value'
import { hidden, hide, show } from 'lib/sequence/component/state/hidden'
import { empty } from 'lib/util/object'
import { displayFavoriteButtonsFromAttribute } from 'app/_shared/action/partial/favorite/displayButtons'

const display = x => (get(x) ? show(x) : hide(x))
const bulkDisplay = bulk(display)

const _setPropertyField = (field) => {
  const fieldValue = get(field)
  setKey(fieldValue.name, 'label', field[G.STATE])
  set(field, fieldValue.value)
}

/**
 * Maps various properties inside the property box.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapProperties = module => async (components, ...args) => {
  const model = module[G.MODEL][G.CHILDREN]?.equipment || {}
  const { title, info, properties, additionalProperties, energy, voltage, size, type } = components

  const bulkMapModel = bulk(map(model))
  bulkMapModel(title, info, additionalProperties)

  get(energy) && _setPropertyField(energy)
  get(voltage) && _setPropertyField(voltage)
  get(size) && _setPropertyField(size)
  get(type) && _setPropertyField(type)

  bulkDisplay(energy, voltage, size, type)

  hidden(energy)
    && hidden(voltage)
    && hidden(size)
    && hidden(type)
    && hide(properties)

  return [components, ...args]
}

/**
 * Sets the image of the equipment.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setImage = module => async (components, ...args) => {
  const model = module[G.MODEL][G.CHILDREN]?.equipment || {}
  const imageCache = model?.[G.CACHE]?.image || {}
  const { image } = components

  const equipmentImage = getFirstItem(imageCache)

  !empty(equipmentImage)
  && (image[G.PROPS].attachment = {
    key: equipmentImage?.value?.attachmentId,
    value: equipmentImage?.attachment?.value,
  })

  return [components, ...args]
}

/**
 * Determines whether the {@code serialLong} or {@code serial} field should be displayed.
 *
 * If {@code obfuscateSerial}, coming from the args, is {@code true}. It will hide the last
 * 5 digits of the serial number.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displaySerial = module => async (components, ...args) => {
  const { serial, serialLong } = components
  const [{ detail: { obfuscateSerial = false } = {} } = {}] = args || []
  const serialLongValue = get(serialLong)

  serialLongValue
    ? show(serialLong) && hide(serial)
    : get(serial) && hide(serialLong) && show(serial)

  obfuscateSerial && set(
    serialLongValue?.length ? serialLong : serial,
    serialLongValue?.length
      ? `${get(serialLong).slice(0, -5)}XXXXX`
      : 'XXXXX',
  )

  return [components, ...args]
}

/**
 * EquipmentInformation Equipment Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => () => async (...args) => asyncPipeSpread(
  mapProperties(module),
  setImage(module),
  displaySerial(module),
  displayFavoriteButtonsFromAttribute('equipment')(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
