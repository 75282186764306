/* eslint-disable object-curly-newline,no-restricted-globals,no-alert,no-unused-vars,
import/no-named-as-default */
/* global G */
import { curry, setKey } from 'lib/util'
import {
  back,
  bulk,
  detail,
  list,
  persistChange,
  persistCheck,
  persistOption,
  persistOptions,
  redirect,
  submit,
} from '@app/_shared/events'
import chain from 'app/_shared/events/util/chain'
import clean from 'app/_shared/events/util/clean'
import { add as addAttachment, apply, create, remove, undo } from 'app/_shared/events/attachment'
import onKey from 'app/_shared/events/util/onKey'
import toOrigin from 'app/_shared/events/redirect/toOrigin'
import search from 'app/_shared/events/search'
import searchWith from 'app/_shared/events/search/with'
import { acl } from 'app/_shared/events/acl'
import modelReset from 'app/_shared/events/model/reset'
import resetActionComponent from 'app/_shared/events/resetActionComponent'
import { document, item, messages, reference, user } from 'app/_shared/events/pubsub'
import listByIds from 'app/_shared/events/list/byIds'
import persistNameOption from 'app/_shared/events/persistNameOption'
import createRedirect from 'app/_shared/events/appbar/createRedirect'
import setAsRead from 'app/_shared/events/message/setAsRead'
import searchAndRecreate from 'app/_shared/events/appbar/searchAndRecreate'
import selectAndAction from 'app/_shared/events/combined/selectAndAction'
import persistChangeAndAction from 'app/_shared/events/combined/persistChangeAndAction'
import persistCheckAndAction from 'app/_shared/events/combined/persistCheckAndAction'
import redirectAndJumpTo from 'app/_shared/events/combined/redirectAndJumpTo'
import { confirm, jump, next } from 'app/_shared/events/step'
import listCountries from 'app/_shared/events/collection/listCountries'
import listUserCountries from 'app/_shared/events/collection/listUserCountries'
import listRequestStatus from 'app/_shared/events/collection/listRequestStatus'
import listTicketStatus from 'app/_shared/events/collection/listTicketStatus'
import listMessageTypes from 'app/_shared/events/collection/listMessageTypes'
import listTicketTypes from 'app/_shared/events/collection/listTicketTypes'
import listOrganisationTypes from 'app/_shared/events/collection/listOrganisationTypes'
import listSerialFieldOptions from 'app/_shared/events/collection/listSerialFieldOptions'
import link from 'app/_shared/events/link'
import loadAttachments from 'app/_shared/events/message/loadAttachments'
import menu from 'app/_shared/events/contextmenu'
import sendAndBackFromMessage from 'app/_shared/events/message/sendAndBackFromMessage'
import searchStepAndRecreate from 'app/_shared/events/searchStepAndRecreate'
import setStepTab, { CONFIRM, LIST, SEARCH, SELECT } from 'app/_shared/events/setStepTab'
import persistSerial from 'app/_shared/events/device/persistSerial'
import missingSerial from 'app/_shared/events/device/missingSerial'
import soon from 'app/_shared/events/soon'
import infinite from 'app/_shared/events/search/infinite'
import listStatusReasons from 'app/_shared/events/collection/listStatusReasons'
import modelSet from 'app/_shared/events/model/set'
import modelSave from 'app/_shared/events/model/save'
import updateUnreadMessagesCount from 'app/ticket/event/updateUnreadMessagesCount'
import updateRequestList from 'app/request/event/updateRequestList'
import session, { settings } from 'app/_shared/session'
import setCountedLabel from 'app/_shared/events/tabs/setCountedLabel'
import persistTab from 'app/_shared/events/tabs/persist'
import sublist from 'app/_shared/events/util/sublist'
import toRef from 'app/_shared/events/redirect/toRef'
import listSoftwarePrograms from 'app/_shared/events/collection/listSoftwarePrograms'
import persistOptionAndAction from 'app/_shared/events/combined/persistOptionAndAction'
import saveAndBackFromMessage from 'app/_shared/events/message/saveAndBackFromMessage'
import { persistUnsaveChange } from 'app/_shared/events/persist/change'
import withModelKey from 'app/_shared/events/redirect/withModelKey'
import remember from 'app/_shared/events/remember'
import persistTypeAndSearchWithRequestCount from 'app/request/event/persistTypeAndSearchWithRequestCount'
import setItem from 'app/request/event/setItem'
import searchProduct from 'app/request/event/searchProduct'
import setProduct from 'app/request/event/setProduct'
import reopenRequest from 'app/request/event/reopenRequest'
import setPartner from 'app/request/event/setPartner'
import persistType from 'app/request/event/persistType'
// TODO: move to _shared:
import sendStatusMessage from 'app/ticket/event/sendStatusMessage'

export default {
  acl: {
    acl,
  },
  onOpen: {
    list: list('verbose'),
    listing: list('short'),
    listWithRefs: list(null),
    listWithRefsAndSetPartner: curry(async (module, component, event) => {
      const data = await list(null, module, component, event)
      return setPartner(data, module, component, event)
    }),
    listVerbose: list('verbose'),
    listByIds: listByIds('verbose'),
    listTicketTypes,
    listMessageTypes: listMessageTypes('request'),
    listCountries,
    listRequestStatus,
    listSoftwarePrograms,
    search: search(null),
    searchWithFilter: chain(
      searchWith(['filter']),
      search(null),
    ),
    viewSearchResult: curry(
      async (module, component, event) => module[G.STATE][G.ACTION][G.COMPONENT][G.STATE][G.LIST],
    ),
    infinite: infinite(null),
    infiniteVerbose: infinite('verbose'),
    reference,
    setAsRead,
    link,
    linkToDevice: toRef('item'),
    linkToTicket: toRef('ticket'),
    setTabSearch: searchWith(['filter']),
  },
  onSubscription: {
    reference,
  },
  onPublication: {
    refreshMessages: messages,
    updateRequestList,
    document,
  },
  onCreate: {
    createRedirect,
  },
  onSearch: {
    searchAndRecreate,
    searchStepAndRecreate,
  },
  onClose: {
    redirect,
    back,
    backAndUndo: chain(
      back,
      undo,
    ),
  },
  onClick: {
    detail,
    back,
    redirect,
    redirectAndJumpTo3: redirectAndJumpTo(3),
    modelRedirect: withModelKey,
    toOrigin,
    submit,
    bulk,
    create,
    remove,
    confirmStep: chain(
      confirm,
      next,
    ),
    toSelect: setStepTab(SELECT),
    toList: setStepTab(LIST),
    toConfirm: setStepTab(CONFIRM),
    toSearch: setStepTab(SEARCH),
    /**
     * Fired whenever the user clicks on the button "I still want to register the device".
     *
     * If the user has the suppressProductDevices setting, redirects them to the device confirmation
     * tab, otherwise it redirects them to the product selection tab.
     *
     * @type {Gaia.AppModule.EventHandler}
     */
    registerDevice: curry((module, component, event) => {
      const tab = settings.suppressProductDevices ? CONFIRM : LIST
      setStepTab(tab)(module, component, event)
    }),
    missingSerial,
    searchProduct,
    searchMaintainedDevice: searchProduct,
    setProduct,
    setItem,
    setItemAndNext: chain(
      setItem,
      confirm,
      next,
    ),
    unsetItem: chain(
      modelReset('item'),
      modelReset('equipment'),
      modelReset('product'),
      modelReset('itemInstalledAt'),
      setStepTab(SEARCH),
    ),
    setLocation: chain(
      modelSet('itemInstalledAt'),
      setStepTab(CONFIRM),
    ),
    unsetLocation: chain(
      modelReset('itemInstalledAt'),
      resetActionComponent('location'),
      setStepTab(SEARCH),
    ),
    saveLocation: modelSave('itemInstalledAt'),
    setParty: modelSet('additionalParty'),
    unsetParty: modelReset('additionalParty'),
    jump,
    next,
    stop,
    close,
    applyAndBack: chain(
      apply,
      clean,
      back,
    ),
    backAndUndo: chain(
      back,
      undo,
    ),
    sendAndBackFromMessage,
    saveAndBackFromMessage,
    reopenRequest,
  },
  onChange: {
    persistChange,
    persistChangeAndAction,
    persistTabAndRemember: chain(
      persistTab,
      remember,
    ),
    persistUnsaveChange,
    persistOption,
    persistOptionAndAction,
    persistOptions,
    persistNameOption,
    persistCheck,
    persistCheckAndAction,
    selectAndAction,
    persistSerial,
    persistType,
    persistTypeAndSearchWithRequestCount,
    persistCountry: persistOptions,
    checkContent: curry((module, component, event) => {
      const { text } = event.detail
      setKey(text ? true : null, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
    }),
    addAttachment,
  },
  onKeyPress: {
    searchProduct: onKey('Enter', searchProduct),
  },
  onStar: {
    soon,
  },
  onAttach: {
    redirect,
    soon,
  },
  onAttachment: {
    addAttachment,
  },
  onSend: {
    sendStatusMessage,
  },
  onFocus: {
    setAsRead,
  },
  onAttachments: {
    loadAttachments,
  },
  list: {
    sub: user,
  },
  item: {
    sub: item,
  },
  cell: {
    sub: updateUnreadMessagesCount,
  },
  onContextMenu: {
    menu: menu(null),
  },
  onPage: {
    remember,
  },
  getOrganisationType: {
    listOrganisationTypes,
  },
  getTicketType: {
    listTicketTypes,
  },
  getRequestStatus: {
    listRequestStatus,
  },
  getStatus: {
    listRequestStatus,
  },
  getStatusReason: {
    listStatusReasons,
  },
  getTicketStatus: {
    listTicketStatus,
  },
  getSelection: {
    listTicketTypes,
    listOrganisationTypes: sublist(
      ['customer', 'independent_contractor', 'internal', 'partner'],
      listOrganisationTypes,
    ),
    listUserCountries,
  },
  getFieldOptions: {
    listSerialFieldOptions,
  },
  getSystemMessageTypes: {
    listMessageTypes: listMessageTypes('system'),
  },
  getLabel: {
    setOwnLabel: setCountedLabel({ ns: 'ticket', key: 'myOpenRequests' }),
    setColleaguesLabel: setCountedLabel({ ns: 'ticket', key: 'otherOpenRequests' }),
  },
  filter: {
    userIsSubmitterAndOpen: curry((module, component, event) => ({
      submitter: session(module).user.key({ wrap: false }),
      status: { max: 79 },
    })),
    userIsSubmitterAndSolved: curry((module, component, event) => ({
      submitter: session(module).user.key({ wrap: false }),
      status: { min: 80 },
    })),
    isNotInactiveIfReq: curry((module, component, event) => {
      const context = module[G.ADAPTER][G.SESSION][G.STATE][G.CONTEXT]
      return context === 'req'
        ? { status: { max: 79 } }
        : {}
    }),
    userIsNotSubmitterAndOpen: curry((module, component, event) => ({
      submitter: `!${session(module).user.key({ wrap: false })}`,
      status: { max: 79 },
    })),
    isSolved: curry((module, component, event) => ({
      status: { min: 80 },
    })),
  },
}
