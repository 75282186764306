/* eslint-disable no-param-reassign,no-unused-vars */
/* global G */
import ui from 'lib/hook/ui/update'
import recall from 'app/_shared/hook/component/recall'
import uiTitle from 'app/equipmentInformation/hooks/uiTitle'
import check from 'app/_shared/hook/check'
import submitAttribute from 'lib/hook/model/submitAttribute'
import readAttribute from 'lib/hook/model/readAttribute'
import cacheAttributeTitle from 'app/_shared/hook/model/cacheAttributeTitle'
import validateAttribute from 'lib/hook/model/validateAttribute'
import action, { currentAction } from 'lib/hook/module/action'
import { confirmation } from 'lib/hook/transaction'

const _indexHooks = {
  before: [],
  after: [
    ui,
  ],
}

const _resultHooks = {
  before: [
    recall,
  ],
  after: [
    ui,
  ],
}

const _treeHooks = {
  before: [
    recall,
  ],
  after: [
    ui,
  ],
}

const _explosionDrawingHooks = {
  before: [
    recall,
  ],
  after: [
    ui,
  ],
}

const _partsHooks = {
  before: [
    readAttribute('article'),
    cacheAttributeTitle('article')('name', 'number'),
  ],
  after: [
    ui,
  ],
}

const _equipmentHooks = {
  before: [
    readAttribute('equipment'),
    cacheAttributeTitle('equipment')('name', 'serial'),
  ],
  after: [
    ui,
  ],
}

const _pdfHooks = {
  before: [],
  after: [
    ui,
  ],
}

const _attachmentHooks = {
  before: [],
  after: [
    uiTitle,
    ui,
  ],
}

const _submitFeedbackHooks = {
  before: [
    validateAttribute('documentationFeedback', 'feedback'),
  ],
  after: [
    ui,
    check,
    submitAttribute('documentationFeedback'),
    confirmation('feedback', 'success'),
    action('successFeedback'),
  ],
}

const _submitFavoriteHooks = {
  before: [],
  after: [
    ui,
    check,
    confirmation('favorite', 'success'),
    action('successFavorite'),
  ],
}

const _successHooks = {
  before: [],
  after: [
    currentAction,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    index: _indexHooks,
    results: _resultHooks,
    equipment: _equipmentHooks,
    part: _partsHooks,
    explosionDrawing: _explosionDrawingHooks,
    soon: _indexHooks,
    pdf: _pdfHooks,
    attachmentWithFeedback: _attachmentHooks,
    attachmentTreeWithFeedback: _attachmentHooks,
    electricDiagram: _indexHooks,
    documentation: _treeHooks,
    drilldown: _treeHooks,
    equipmentDocumentation: _treeHooks,
    equipmentErrorTrees: _treeHooks,
    equipmentBulletin: _treeHooks,
    serviceReference: _treeHooks,
    submitFeedback: _submitFeedbackHooks,
    successFeedback: _successHooks,
    submitFavorite: _submitFavoriteHooks,
    successFavorite: _successHooks,
  },
}

export default hooks
