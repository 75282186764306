/* global G */
import { curry } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { get, set } from 'lib/sequence/component/state/value'

const onScan = async (module, component, event) => {
  console.log('scanned:', event)

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  const { enableScanning } = find(actionComponent)
  console.log('enable scanning in found:', enableScanning)

  const setUpdate = (value) => {
    set(enableScanning, value)
    module[G.ADAPTER][G.UI].update(module)
  }

  // TODO: This approach doesn't work
  // Maybe we need to disable scanning while some "synchronous" logic is executed
  setUpdate(false)
  setTimeout(() => setUpdate(false), 5000)

  return {
    enableScanning: false
  }
}

export default curry(onScan)
