/* eslint-disable no-unused-vars */
/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { curry } from 'lib/util'

/**
 * Sets the search term for the list displaying the found parts and forces
 * it to rerender.
 *
 * @param {Gaia.AppModule.Spec} module - module composition
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent} event - triggered event
 * @param keepSorting
 * @returns {Promise<void>}
 */
const searchPartAndRecreate = async (module, component, event, keepSorting = true) => {
  try {
    const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
    const { footer } = sequenceComponentFindProxy(actionComponent)
    const { container } = asObject(footer[G.CHILDREN])
    const { searchField, list } = asObject(container[G.CHILDREN])

    const { term } = event.detail

    list[G.STATE][G.META] = { term }

    // Force rerender of list
    list[G.STATE].reload = {}
    module[G.ADAPTER][G.UI].update(module)
  } catch (e) {
    console.error(e)
  }
}

export default curry(searchPartAndRecreate)
