/* global G */
import ui from 'lib/hook/ui/update'
import bulk from 'lib/hook/model/bulk'
import confirm from 'lib/hook/router/confirm'
import clear from 'app/_shared/hook/model/clear'
import { setKey } from 'lib/util'
import stepper from 'app/_shared/hook/stepper'
import read from 'lib/hook/model/read'
import uiTitle from 'app/request/hook/uiTitle'
import create from 'app/_shared/hook/appbar/create'
import mapAttribute from 'app/_shared/hook/model/mapAttribute'
import recall from 'app/_shared/hook/component/recall'
import updateCache from 'app/_shared/hook/model/updateCache'
import submitInitialAttachmentMessageAndRedirect from './hook/submitInitialAttachmentMessageAndRedirect'

const _baseHooks = {
  before: [],
  after: [
    ui,
  ],
}

const _readHooks = {
  before: [
    read,
  ],
  after: [
    ui,
  ],
}

const _indexHooks = {
  before: [
    create,
    recall,
  ],
  after: [
    ui,
  ],
}

const _locationHooks = {
  before: [
    mapAttribute('itemInstalledAt'),
  ],
  after: [
    ui,
    confirm,
  ],
}

const _createHooks = {
  before: [
    // Clears the module error, so that we can still use redirect to navigate the drawer actions.
    // Should be replaced by using force redirect
    obj => async (...args) => {
      setKey(null, G.ERROR, obj[G.STATE])
      return args
    },
  ],
  after: [
    stepper,
    ui,
    confirm,
  ],
}

const _bulkHooks = {
  before: [
    // validate, // seq validate
  ],
  after: [
    ui, // show validation errors, if exist
    bulk, // seq create/update, getValue, transform, post/put
    // submitFirstMessage, // not needed, server handles this.
    // submitAttachments, // handled by submitInitialAttachmentMessageAndRedirect
    // redirect, // same as above
    submitInitialAttachmentMessageAndRedirect,
    updateCache,
  ],
}

const _detailHooks = {
  before: [
    clear,
    read,
  ],
  after: [
    uiTitle,
    ui,
    confirm,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    index: _indexHooks,
    create: _createHooks,
    location: _locationHooks,
    bulk: _bulkHooks,
    created: _readHooks,
    detail: _detailHooks,
    description: _readHooks,
    reopenRequest: _readHooks,
    attachments: _baseHooks,
    messageAttachments: _baseHooks,
    showAttachments: _baseHooks,
  },
}

export default hooks
