/* global G */
import { curry, setKey } from 'lib/util'
import chain from 'app/_shared/events/util/chain'
import session from 'app/_shared/session'
import getUserRole from 'app/_shared/events/user/role'
import search from 'app/_shared/events/search'
import { persistType } from 'app/request/event/persistType'
import setStepTab, { SEARCH, SELECT } from 'app/_shared/events/setStepTab'
import { next } from 'app/_shared/events/step'

/**
 * Perform a device search based on the user's role and save it in the action component's state.
 *
 * @type {(function(...[*]): (*))|*}
 */
const searchWithRequestCount = curry(async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { user, organisation, person, isContext } = session(module)

  const userRole = getUserRole(user.ref()?.value?.roles, module, component, null)?.key

  const customerFilters = isContext('req')
    ? { status: { max: 79 } }
    : {}

  const result = await search('defaultWithRequestCount', module, component, userRole === 'FieldServiceEngineer'
    ? { filter: { maintainedBy: person.key({ wrap: true })[0] } }
    : { filter: { installedAt: organisation.key({ wrap: true }[0]), ...customerFilters } })

  setKey(result, G.LIST, actionComponent[G.STATE])
})

/**
 * Set the device tab according to the previous search result.
 *
 * @type {(function(...[*]): (*))|*}
 */
const setDeviceStepTab = curry(async (module, component, event) => await setStepTab(
  module[G.STATE][G.ACTION][G.COMPONENT][G.STATE][G.LIST]?.length
    ? SELECT
    : SEARCH,
  'device',
)(module, component, event))

export default chain(
  persistType,
  searchWithRequestCount,
  setDeviceStepTab,
  next,
)
